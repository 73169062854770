.m-pager {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	line-height: 1;
	
	> span {
		display: inline-block;
		margin: 0 0.25em;
		
		&.current {
			display: block;
			padding: 0.25em 0.4em;
			border: 1px solid #0c8eba;
			color: #0c8eba;
			font-weight: bold;
		}
		&.number {
			> a {
				display: block;
				padding: 0.25em 0.4em;
				border: 1px solid #0c8eba;
				background-color: #0c8eba;
				color: #ffffff;
			}
		}
	}
}