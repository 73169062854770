.m-localnav {
	padding: 15px 20px 25px;
	background-color: #dde5ed;
	border-radius: 12px;
	
	&__ttl {
		color: #0089b6;
		// font-size: 1.8rem;
		font-size: 2.4rem;
		font-weight: bold;
	}
	&__list {
		display: flex;
		justify-content: center;
		
		&:not(:first-child) {
			margin-top: 15px;
		}
		
		li {
			flex: 1 1 auto;
			max-width: percentage( 325 / 1160 );
			
			&:not(:first-child) {
				margin-left: percentage( 28 / 1160 );
			}
			a {
				display: block;
				padding: 1em;
				border: 1px solid #0089b6;
				border-radius: 12px;
				background-color: #ffffff;
				color: #0089b6;
				text-align: center;
				font-size: 1.8rem;
				font-weight: bold;
				
				&.is-active {
					background-color: #0089b6;
					color: #ffffff;
				}
			}
		}
	}
}