.m-products {
	&-side {
		position: fixed;
		left: 0;
		top: 0;
		z-index: $z-side;
		width: 100%;
		height: 100%;
		pointer-events: none;
		
		&__overlay {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0;
			pointer-events: none;
			transition: opacity .3s;
			
			html.is-side-visible & {
				opacity: 0.3;
				pointer-events: auto;
			}
		}
		&__switch {
			position: absolute;
			right: 0;
			top: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 72px;
			height: 198px;
			background-color: #fff;
			border: 2px solid #0c8eba;
			border-right: 0;
			border-radius: 8px 0 0 8px;
			color: #0c8eba;
			font-size: 2.4rem;
			line-height: 1.2;
			pointer-events: auto;
			transform: translateY(-50%);
			cursor: pointer;
			
			@media #{$sp} {
				width: spvw(40);
				height: spvw(120);
				font-size: spvw(14);
			}
			html.is-side-visible & {
				right: auto;
				left: 56px;
				
				@media #{$sp} {
					left: spvw(8);
				}
			}
			
			span {
				display: inline-block;
				width: 1em;
				
				&:before {
					display: inline-block;
					width: 19px;
					height: 19px;
					margin-left: 2px;
					margin-bottom: 4px;
					background: url(#{$img}common/side-arw.png) no-repeat;
					background-size: 100% 100%;
					content: '';
					
					@media #{$sp} {
						width: spvw(14);
						height: spvw(14);
						margin-left: spvw(2);
						margin-bottom: spvw(4);
					}
					html.is-side-visible & {
						transform: rotate(180deg);
					}
				}
			}
		}
		&__body {
			position: absolute;
			left: 128px;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 22px 110px 22px 30px;
			background-color: #fff;
			border-left: 2px solid #3b87b2;
			pointer-events: none;
			opacity: 0;
			transform: translateX(100%);
			
			@media #{$sp} {
				left: spvw(48);
				padding: spvw(16) spvw(32) spvw(16) spvw(16);
			}
			html.is-side-visible & {
				opacity: 1;
				transform: translateX(0%);
				pointer-events: auto;
			}
		}
		/*
		&.is-ready &__body {
			transition: opacity .3s, transform .6s ease-out;
		}
		*/
		&__scroll {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			
			&::-webkit-scrollbar{
				display:none;
			}
		}
		&__scrollbar {
			position: absolute;
			right: 52px;
			top: 22px;
			bottom: 22px;
			width: 2px;
			background-color: #b6b6b6;
			
			@media #{$sp} {
				right: spvw(14);
				top: spvw(16);
				bottom: spvw(16);
			}
		}
		&__scrollbtn {
			position: absolute;
			left: -7px;
			width: 16px;
			height: 16px; // default
			background-color: #0089b6;
			cursor: pointer;
			
			@media #{$sp} {
				left: spvw(-3);
				width: spvw(9);
				height: spvw(16);
			}
		}
	}
	&-category {
		&:not(:first-child) {
			margin-top: 30px;
			
			@media #{$sp} {
				margin-top: spvw(30);
			}
		}
		&__ttl {
			font-size: 3.8rem;
			line-height: 1;
			
			@media #{$tb} {
				font-size: 2.8rem;
			}
			@media #{$sp} {
				font-size: spvw(22);
			}
			> span {
				position: relative;
				display: flex;
				align-items: center;
				color: #0c8eba;
				
				> img {
					margin-right: 24px;
					
					@media #{$sp} {
						width: spvw(48);
						margin-right: spvw(16);
					}
				}
				&:before {
					position: absolute;
					left: 48px;
					right: 0;
					bottom: 0;
					height: 4px;
					background-color: #3b87b2;
					content: '';
					
					@media #{$sp} {
						left: spvw(24);
						height: spvw(2);
					}
				}
				/*
				&:after {
					display: inline-block;
					width: 0.28em;
					height: 0.28em;
					border-top: 3px solid #3b87b2;
					border-right: 3px solid #3b87b2;
					margin-left: 0.5em;
					transform: rotate(45deg);
					content: '';
					
					@media #{$sp} {
						border-top-width: 2px;
						border-right-width: 2px;
					}
				}
				*/
			}
		}
		&__body {
			display: flex;
			margin-top: 40px;
			padding-left: 45px;
			padding-right: 30px;
			
			@media #{$tb} {
				padding-left: 0;
				padding-right: 0;
			}
			@media #{$sp} {
				display: block;
				margin-top: spvw(32);
			}
		}
		&__column {
			width: calc( 100% * 232 / 1057 );
			
			@media #{$sp} {
				width: 100%;
			}
			&.is-double {
				width: calc( 100% * 510 / 1057 );
				
				@media #{$sp} {
					width: 100%;
				}
			}
			&.is-full {
				width: 100%;
			}
			&.is-image {
				width: calc( 100% * 410 / 1057 );
				
				@media #{$sp} {
					width: 100%;
				}
			}
			&.is-with-image {
				width: calc( 100% * 610 / 1057 );
				
				@media #{$sp} {
					width: 100%;
				}
			}
			&:not(:nth-child(4n+1)) {
				margin-left: calc( 100% * 43 / 1057 );
				
				@media #{$sp} {
					margin-left: 0;
				}
			}
			&:not(:first-child) {
				@media #{$sp} {
					margin-top: spvw(30);
				}
			}
		}
		&__sub {
			width: 100%;
			
			&:not(:first-child) {
				margin-top: 48px;
			}
			&__ttl {
				padding-bottom: 6px;
				color: #0c8eba;
				font-size: 2.2rem;
				line-height: 1;
				border-bottom: 1px solid #0c8eba;
				
				@media #{$tb} {
					font-size: 1.8rem;
				}
			}
			&__list {
				margin-top: 16px;
			}
		}
	}
}

html.is-side-visible {
	overflow: hidden;
}