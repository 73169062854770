@charset "utf-8";

/*===============================================
 home
===============================================*/
.p-home {
	&-kv {
		padding-bottom: 64px;
		
		@media #{$sp} {
			padding-bottom: 0;
		}
		&__slider {
			position: relative;
			width: 100%;
		}
		&__container {
			position: relative;
			width: 100%;
			padding-top: percentage( 466 / 1400 );
			background: url(#{$img}home/loading.gif) no-repeat center center;
			
			@media #{$sp} {
				padding-top: percentage( 466 / 750 );
				background-size: 32px 32px;
			}
		}
		&__item {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-color: #fff;
			overflow: hidden;
			opacity: 0; // default
			
			a {
				display: block;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				
				@media #{$sp} {
					max-width: 187%;
					width: 187%;
					margin-left: -43.5%;
					margin-right: -43.5%;
				}
			}
		}
		&__overlay {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			
			&.is-with-bg {
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: #000;
					opacity: 0.3;
					content: '';
				}
			}
			&__in {
				position: relative;
				display: flex;
				width: 100%;
				
				&.is-left {
					
				}
				&.is-right {
					
				}
			}
			&__center {
				width: 100%;
				padding-top: calc( 100vw * 24 / 1400 );
				text-align: center;
			}
			&__in.is-left &__center {
				text-align: left;
				padding-left: calc( 100vw * 80 / 1400 );
			}
			&__in.is-right &__center {
				text-align: right;
				padding-right: calc( 100vw * 80 / 1400 );
			}
			&__catch,
			&__shadow {
				display: block;
				color: #fff;
				// font-size: calc( 100vw * 28 / 1400 );
				font-size: calc( 100vw * 36 / 1400 );
				font-weight: 400;
				line-height: 1.5;
				letter-spacing: 0.05em;
				
				@media #{$sp} {
					font-size: spvw(16);
				}
			}
			&__shadow {
				position: absolute;
				left: 0;
				top: calc( 100vw * 24 / 1400);
				width: 100%;
				text-stroke: 7px #fff;
				-webkit-text-stroke: 7px #fff;
				
				@media #{$sp} {
					text-stroke: 4px #fff;
					-webkit-text-stroke: 4px #fff;
				}
			}
			&__in.is-left &__shadow {
				left: calc( 100vw * 80 / 1400);
			}
			&__in.is-right &__shadow {
				left: auto;
				right: calc( 100vw * 80 / 1400);
			}
			&__center &__catch {
				position: relative;
				color: #3b87b2;
			}
			&__btn {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: calc( 100vw * 194 / 1400 );
				height: calc( 100vw * 54 / 1400 );
				margin: calc( 100vw * 48 / 1400 ) auto 0;
				border: 1px solid #0c8eba;
				border-radius: calc( 100vw * 7 / 1400 );
				color: #0c8eba;
				font-size: calc( 100vw * 15 / 1400 );
				
				@media #{$sp} {
					width: spvw(194 * 0.6);
					height: spvw(54 * 0.6);
					margin: spvw(16) auto 0;
					font-size: spvw(10);
				}
				&:after {
					position: absolute;
					right: calc( 100vw * 20 / 1400 );
					top: 50%;
					width: calc( 100vw * 9 / 1400 );
					height: calc( 100vw * 9 / 1400 );
					border-top: 2px solid #0c8eba;
					border-right: 2px solid #0c8eba;
					transform: translateY(-50%) rotate(45deg);
					content: '';
				}
			}
			&__center &__btn {
				// background-color: rgba(59, 135, 178, 0.6);
				background-color: rgba(255,255,255,0.75);
			}
			&__in.is-left &__btn {
				margin-left: 0;
			}
			&__in.is-right &__btn {
				margin-right: 0;
			}
		}
		&__nav {
			position: absolute;
			left: 50%;
			bottom: -32px;
			z-index: 1;
			display: flex;
			transform: translateX(-50%);
			
			@media #{$sp} {
				left: auto;
				right: spvw(8);
				bottom: spvw(8);
				transform: none;
			}
			> li {
				width: 15px;
				height: 15px;
				margin: 0 7px;
				border-radius: 50%;
				background-color: #edf5f7;
				transition: background-color .2s linear;
				cursor: pointer;
				
				@media #{$sp} {
					width: spvw(15);
					height: spvw(15);
					margin: 0 spvw(7);
				}
				
				&.is-active {
					background-color: #3b87b2;
					cursor: default;
					pointer-events: none;
				}
			}
		}
		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			appearance: none;
			display: block;
			width: calc( 100vw * 32 / 1400 );
			height: calc( 100vw * 32 / 1400 );
			padding: 0;
			margin: 0;
			border: 0;
			outline: 0;
			border-left: 2px solid #3b87b2;
			border-top: 2px solid #3b87b2;
			background: transparent;
			cursor: pointer;
		}
		&__prev {
			left: calc( 100vw * 32 / 1400 );
			transform: translateY(-50%) rotate(-45deg);
		}
		&__next {
			right: calc( 100vw * 32 / 1400 );
			transform: translateY(-50%) rotate(135deg);
		}
	}
	&-banners {
		@media #{$sp} {
			margin-top: 4vw;
		}
		&__container {
			display: flex;
			justify-content: space-between;
			width: 100%;
			box-sizing: border-box;
			
			@media #{$sp} {
				display: block;
			}
		}
		&__left {
			width: calc( 100% * 566 / 1200 );
			padding-left: calc( 100% * 32 / 1200 );
			
			@media #{$sp} {
				width: 100%;
				padding-left: 0;
			}
		}
		&__right {
			width: calc( 100% * 570 / 1200 );
			max-width: 570px;
			opacity: 0;
			
			@media #{$sp} {
				width: 100%;
				max-width: 100%;
				margin-top: spvw(30);
			}
			&.is-ready {
				opacity: 1;
			}
			a {
				display: block;
				
				> img {
					display: block;
				}
			}
		}
		&-item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 120px;
			border: 5px solid #dde5ed;
			
			&:not(:first-child) {
				margin-top: 16px;
			}
			dl {
				display: flex;
				font-size: 2.4rem;
				font-weight: $fw-bold;
				line-height: 1;
				
				dd {
					padding-left: 6px;
					margin-left: 6px;
					border-left: 1px solid #000;
				}
			}
			time {
				position: absolute;
				left: 20px;
				bottom: 12px;
				font-size: 1.5rem;
				line-height: 1;
			}
			p {
				position: absolute;
				right: 20px;
				bottom: 12px;
				font-size: 1.5rem;
				line-height: 1;
				
				a {
					color: #3b87b2;
					
					&:after {
						display: inline-block;
						width: 0.4em;
						height: 0.4em;
						margin-left: 0.5em;
						border-top: 1px solid #3b87b2;
						border-right: 1px solid #3b87b2;
						vertical-align: 0.1em;
						transform: rotate(45deg);
						content: '';
					}
				}
			}
		}
	}
	&-news {
		&__ttl {
			color: #333333;
			font-size: 2.0rem;
			font-weight: $fw-bold;
			line-height: 1;
		}
		&__list {
			margin-top: 16px;
		}
		&-item {
			display: flex;
			padding: 10px 0;
			border-bottom: 1px dotted #0089b6;
			
			@media #{$sp} {
				flex-wrap: wrap;
			}
			&__date {
				padding-right: 16px;
				font-size: 1.6rem;
				line-height: #{(20 / 14)};
				white-space: nowrap;
			}
			&__cat {
				display: flex;
				width: 60px;
				height: 20px;
				border-radius: 10px;
				background-color: #0089b6;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				font-size: 1.2rem;
				line-height: 1;
				white-space: nowrap;
			}
			&__ttl {
				padding-left: 11px;
				font-size: 1.6rem;
				font-weight: $fw-normal;
				line-height: #{(20 / 14)};
				
				@media #{$sp} {
					width: 100%;
					padding-left: 0;
					padding-top: 5px;
				}
				> a {
					color: #0089b6;
				}
			}
		}
		&__links {
			margin-top: 20px;
			font-size: 1.6rem;
			line-height: #{(20 / 14)};
			
			> a {
				margin-left: 2em;
				color: #0089b6;
				
				&:first-child {
					margin-left: 0;
				}
				&:before {
					display: inline-block;
					width: 8px;
					height: 13px;
					margin-right: 5px;
					vertical-align: -1px;
					background: url(#{$img}common/link-arw.png) no-repeat center center;
					content: '';
				}
			}
		}
	}
	&-products {
		&__in {
			padding-top: 56px;
			padding-bottom: 64px;
			
			@media #{$sp} {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		&__nav {
			ul {
				display: flex;
				justify-content: space-between;
				
				li {
					width: calc( 100% * 370 / 1200 );
					
					@media #{$sp} {
						width: calc( 100% * 390 / 1200 );
					}
					a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: 61px;
						border-radius: 12px 12px 0 0;
						background-color: #fff;
						text-align: center;
						color: #000;
						font-size: 2.2rem;
						line-height: 1.3;
						
						@media #{$tb} {
							font-size: 1.8rem;
						}
						@media #{$sp} {
							border-radius: spvw(8) spvw(8) 0 0;
							font-size: spvw(11);
							line-height: 1.2;
						}
						&.is-active {
							background-color: #3b87b2;
							color: #fff;
							
							&:hover {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&__container {
			background-color: #fff;
			border-radius: 0 0 12px 12px;
			overflow: hidden;
			
			@media #{$sp} {
				border-radius: 0 0 spvw(8) spvw(8);
			}
		}
		&-tab {
			padding: 40px 34px 116px;
			
			@media #{$sp} {
				padding: spvw(20) spvw(16) spvw(16);
			}
			#product-support & {
				@media #{$sp} {
					padding: 0;
				}
			}
		}
		&-purpose {
			&__container {
				display: flex;
				flex-wrap: wrap;
				
				@media #{$sp} {
					display: block;
				}
			}
			&-item {
				width: calc( 100% * 315 / 1080 );
				
				@media #{$sp} {
					width: 100%;
				}
				&:nth-child(n+4) {
					margin-top: calc( 100% * 67.5 / 1080 );
					
					@media #{$sp} {
						margin-top: spvw(20);
					}
				}
				&:not(:nth-child(3n+1)) {
					margin-left: calc( 100% * 67.5 / 1080 );
					
					@media #{$sp} {
						margin-left: 0;
					}
				}
				&:not(:first-child) {
					@media #{$sp} {
						margin-top: spvw(20);
					}
				}
				&__box {
					display: block;
					border: 1px solid #3b87b2;
					border-radius: 10px;
					overflow: hidden;
					
					@media #{$sp} {
						border-radius: spvw(8);
					}
				}
				&__in {
					display: flex;
				}
				&__icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100px;
					min-height: 112px;
					margin: 0;
					background-color: #3b87b2;
					
					@media #{$sp} {
						width: spvw(64);
						min-height: spvw(64);
					}
				}
				&__label {
					flex: 1 1 auto;
					display: flex;
					align-items: center;
					padding: 16px;
					background-color: #dde5ed;
					color: #3b87b2;
					font-size: 1.8rem;
					line-height: 1.2;
					
					@media #{$sp} {
						padding: spvw(12);
						font-size: spvw(16);
					}
					small {
						font-size: 1.4rem;
						
						@media #{$sp} {
							font-size: spvw(11);
						}
					}
				}
			}
		}
		&-support {
			$supportWidth: 1400; // px
			$supportHeight: 848; // px
			
			position: relative;
			max-width: 1400px;
			margin: 0 auto;
			
			@media #{$sp} {
				margin-left: spvw(-20);
				margin-right: spvw(-40);
			}
			> img {
				display: block;
			}
			&__buttons {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				
				> button {
					position: absolute;
					padding: 0;
					margin: 0;
					border: 0;
					outline: 0;
					background: transparent;
					cursor: pointer;
					
					> img {
						display: block;
						width: 100%;
						height: auto;
					}
					&:nth-child(1) {
						left: calc( 100% * 175 / #{$supportWidth} );
						top: calc( 100% * 94 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
					&:nth-child(2) {
						left: calc( 100% * 496 / #{$supportWidth} );
						top: calc( 100% * 140 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
					&:nth-child(3) {
						left: calc( 100% * 869 / #{$supportWidth} );
						top: calc( 100% * 148 / #{$supportHeight} );
						width: calc( 100% * 243 / #{$supportWidth} );
					}
					&:nth-child(4) {
						left: calc( 100% * 186 / #{$supportWidth} );
						top: calc( 100% * 395 / #{$supportHeight} );
						width: calc( 100% * 318 / #{$supportWidth} );
					}
					&:nth-child(5) {
						left: calc( 100% * 632 / #{$supportWidth} );
						top: calc( 100% * 370 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
					&:nth-child(6) {
						left: calc( 100% * 344 / #{$supportWidth} );
						top: calc( 100% * 475 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
					&:nth-child(7) {
						left: calc( 100% * 946 / #{$supportWidth} );
						top: calc( 100% * 533 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
					&:nth-child(8) {
						left: calc( 100% * 460 / #{$supportWidth} );
						top: calc( 100% * 653 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
					&:nth-child(9) {
						left: calc( 100% * 108 / #{$supportWidth} );
						top: calc( 100% * 733 / #{$supportHeight} );
						width: calc( 100% * 265 / #{$supportWidth} );
					}
					&:nth-child(10) {
						left: calc( 100% * 557 / #{$supportWidth} );
						top: calc( 100% * 727 / #{$supportHeight} );
						width: calc( 100% * 244 / #{$supportWidth} );
					}
				}
			}
			&__notes {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
			}
			&__note {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 0;
				pointer-events: none;
				transform: scale(0.8);
				transition: opacity .3s, transform .3s ease-out;
				
				&.is-visible {
					opacity: 1;
					pointer-events: auto;
					transform: scale(1);
				}
				&__box {
					min-width: 515px;
					background-color: #0c8eba;
					
					@media only screen and (max-width: 1480px) {
						min-width: 405px;
					}
					@media #{$tb} {
						min-width: tbvw(410);
					}
					@media #{$sp} {
						min-width: spvw(210 * 0.75);
					}
				}
				&:nth-child(1) &__box {
					margin-left: -52%;
					margin-top: -12%;
				}
				&:nth-child(2) &__box {
					margin-left: -15%;
					margin-top: -25%;
				}
				&:nth-child(3) &__box {
					margin-left: 40%;
					margin-top: -24%;
				}
				&:nth-child(4) &__box {
					margin-left: -52%;
				}
				&:nth-child(5) &__box {
					margin-left: 6%;
				}
				&:nth-child(6) &__box {
					margin-left: -39%;
					margin-top: 11%;
				}
				&:nth-child(7) &__box {
					margin-left: 52%;
					margin-top: 22%;
				}
				&:nth-child(8) &__box {
					margin-left: -19%;
					margin-top: 40%;
				}
				&:nth-child(9) &__box {
					margin-left: -52%;
					margin-top: 34%;
				}
				&:nth-child(10) &__box {
					margin-left: -6%;
					margin-top: 20%;
				}
				&__heading {
					position: relative;
					padding: 10px 54px 10px 26px;
					
					@media #{$tb} {
						padding: tbvw(10) tbvw(54) tbvw(10) tbvw(26);
					}
					@media #{$sp} {
						padding: spvw(5 * 0.75) spvw(27 * 0.75) spvw(5 * 0.75) spvw(13 * 0.75);
					}
				}
				&__ttl {
					color: #fff;
					font-size: 2.7rem;
					font-weight: $fw-bold;
					line-height: 1;
					
					@media only screen and (max-width: 1480px) {
						font-size: 2.2rem;
					}
					@media #{$tb} {
						font-size: tbvw(22);
					}
					@media #{$sp} {
						font-size: spvw(11 * 0.75);
					}
				}
				&__close {
					position: absolute;
					right: 10px;
					top: 50%;
					width: 32px;
					height: 32px;
					padding: 0;
					border: 0;
					outline: 0;
					background: transparent url(#{$img}home/product-support-close.svg) no-repeat;
					background-size: 100% 100%;
					transform: translateY(-50%);
					cursor: pointer;
					
					@media only screen and (max-width: 1480px) {
						width: 26px;
						height: 26px;
					}
					@media #{$tb} {
						width: tbvw(26);
						height: tbvw(26);
					}
					@media #{$sp} {
						width: spvw(13 * 0.75);
						height: spvw(13 * 0.75);
					}
				}
				&__body {
					padding: 0 4px 4px 4px;
					
					> ul {
						padding: 8px 14px;
						background-color: #fff;
						
						@media only screen and (max-width: 1480px) {
							padding-top: 6px;
							padding-bottom: 6px;
						}
						@media #{$tb} {
							padding: tbvw(6) tbvw(14);
						}
						@media #{$sp} {
							padding: 0 spvw(4);
						}
						> li {
							&:not(:first-child) {
								border-top: 2px dotted #0c8eba;
								
								@media #{$sp} {
									border-top-width: 1px;
								}
							}
							> a {
								position: relative;
								display: block;
								padding: 10px 22px 10px 10px;
								color: #212121;
								font-size: 2.3rem;
								line-height: 1.2;
								
								@media only screen and (max-width: 1480px) {
									padding-top: 8px;
									padding-bottom: 8px;
									font-size: 1.7rem;
								}
								@media #{$tb} {
									padding: tbvw(8) tbvw(22) tbvw(10) tbvw(10);
									font-size: tbvw(18);
								}
								@media #{$sp} {
									padding: spvw(4 * 0.75) spvw(11 * 0.75) spvw(5 * 0.75) spvw(3);
									font-size: spvw(10 * 0.75);
								}
								&:after {
									position: absolute;
									right: 3px;
									top: 50%;
									width: 13px;
									height: 20px;
									background: url(#{$img}home/product-support-arw.svg) no-repeat;
									background-size: 100% 100%;
									transform: translateY(-50%);
									content: '';
									
									@media only screen and (max-width: 1480px) {
										width: 11px;
										height: 17px;
									}
									@media #{$tb} {
										width: tbvw(11);
										height: tbvw(17);
									}
									@media #{$sp} {
										width: spvw(5.5 * 0.75);
										height: spvw(8.5 * 0.75);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&-company {
		&__in {
			padding-top: 56px;
			padding-bottom: 64px;
			
			@media #{$sp} {
				padding-top: spvw(32);
				padding-bottom: spvw(32);
			}
		}
	}
	&-sustainability {
		background: url(#{$img}home/sustainability-bg.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			/*
			padding-top: 56px;
			padding-bottom: 215px;
			
			@media #{$sp} {
				padding-top: spvw(32);
				padding-bottom: spvw(48);
			}
			*/
		}
	}
	&-recruit {
		&__bnr {
			position: relative;
		}
		&__catch {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			
			> span {
				text-shadow: 0 0 4px #1a4e8a;
				color: #fff;
				font-size: 1.7vw;
				font-weight: $fw-bold;
				line-height: 1.5;
				letter-spacing: 0.06em;
				
				@media #{$sp} {
					font-size: 3.6vw;
				}
			}
		}
		&__caption {
			margin-top: 0.5em;
			text-align: center;
			color: #0c8eba;
			font-weight: $fw-bold;
			
			@media #{$sp} {
				font-size: 1.4rem;
			}
		}
	}
}