.m-sticky {
	position: fixed;
	bottom: 15px;
	right: 10px;
	background-color: #fff;
	z-index: $z-sticky;
	transition: opacity .6s;
	
	@media #{$sp} {
		bottom: 56px;
	}
	/*
	&.is-with-agreement {
		bottom: 210px;
	}
	*/
	&.is-hidden {
		opacity: 0;
		pointer-events: none;
	}
	a {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 200px;
		height: 60px;
		padding-left: 40px;
		border-radius: 3px;
		background-color: #0089b7;
		color: #fff;
		font-size: 1.6rem;
		line-height: calc( 21 / 16 );
		text-decoration: none;
		
		@media #{$tb} {
			
		}
		@media #{$sp} {
			
		}
		&:hover {
			opacity: 0.8;
		}
		&:before {
			position: absolute;
			left: 12px;
			top: 50%;
			width: 21px;
			height: 15px;
			background: url(#{$img}common/footer-email-w.png);
			transform: translateY(-50%);
			content: '';
		}
	}
	&__close {
		display: block;
		width: 25px;
		height: 23px;
		position: absolute;
		top: -8px;
		right: 0;
		background: url(#{$img}common/fixed_contactButton_close.svg) no-repeat center/contain;
		text-indent: -999999px;
		cursor: pointer;
		z-index: 1;
	}
}