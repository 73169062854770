.m-list {
	> li {
		font-size: 1.6rem;
		
		@media #{$tb} {
			font-size: 1.3rem;
		}
	}
	&-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		
		&:not(:first-child) {
			margin-top: 20px;
		}
	}
	&-column {
		width: 24%;
		
		&--w2 {
			width: 49%;
		}
		&--w2s {
			width: 46%;
			
			@media #{$sp} {
				width: 100%;
				
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
		}
	}
	&-sect {
		&:not(:first-child) {
			margin-top: 20px;
		}
	}
	&-sect & {
		padding-left: 18px;
	}
	&-parent {
		> li {
			color: #0c8eba;
			font-size: 1.5rem;
			font-weight: $fw-bold;
		}
	}
	&-parent & {
		padding-left: 1em;
		font-weight: $fw-normal;
	}
	&-dot01,
	&-decimal01 {
		font-size: 1.2rem;
		line-height: #{(26 / 12)};
		
		li {
			position: relative;
		}
		ul {
			margin-left: 1.5em;
		}
	}
	&-dot01 {
		li {
			padding-left: 1em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 0.8em;
				transform: rotate(45deg);
				width: 4px;
				height: 4px;
				border-top: 2px solid #333;
				border-right: 2px solid #333;
				content: '';
			}
		}
	}
	&-decimal01 {
		counter-reset: my-counter;
		
		li {
			padding-left: 1.2em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 0.7em;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1em;
				height: 1em;
				border: 1px solid #333;
				border-radius: 1em;
				font-size: 80%;
				line-height: 1;
				content: counter(my-counter);
				counter-increment: my-counter;
			}
		}
		ol {
			counter-reset: my-counter;
		}
	}
	&-decimal02 {
		counter-reset: my-counter;
		color: #0062a0;
		font-size: 1.8rem;
		font-weight: $fw-bold;
		
		li {
			position: relative;
			padding-left: 2.5em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 0.1em;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1.5em;
				height: 1.5em;
				border: 1px solid #0062a0;
				font-weight: $fw-normal;
				line-height: 1;
				content: counter(my-counter);
				counter-increment: my-counter;
			}
		}
		ol {
			counter-reset: my-counter;
		}
	}
	&-dot02 {
		li {
			position: relative;
			padding-left: 1em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 0.8em;
				transform: rotate(45deg);
				width: 4px;
				height: 4px;
				background-color: #333;
				border-radius: 2px;
				content: '';
			}
		}
	}
	a {
		color: #0089b6;
	}
}