.p-news {
	&__heading {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	&__main {
		padding-bottom: 100px;
	}
	&__date {
		line-height: 1;
	}
	&__cats {
		margin-left: 10px;
	}
	&__content {
		padding-top: 40px;
		border-top: 1px solid #dedede;
		line-height: 1.8;
		
		p {
			&:not(:first-child) {
				margin-top: 1.8em;
			}
		}
		a {
			color: #0c8eba;
		}
	}
}