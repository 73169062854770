.m-contact {
	padding: 20px 22px;
	border: 3px solid #3b87b2;
	
	&__in {
		display: flex;
		align-items: center;
		
		@media #{$sp} {
			flex-direction: column;
		}
	}
	&__ttl {
		font-size: 1.8rem;
		font-weight: $fw-bold;
		white-space: nowrap;
	}
	&__content {
		padding-left: 50px;
		
		@media #{$sp} {
			padding-left: 0;
			margin-top: 0.5em;
		}
		p {
			font-size: 1.4rem;
			line-height: #{(30 / 14)};
		}
	}
	&__link {
		margin-left: auto;
		
		@media #{$sp} {
			margin-left: 0;
			margin-top: 1em;
		}
		a {
			display: block;
			padding-left: 35px;
			background: url(../images/common/footer-email.png) no-repeat left center;
			color: #0089b6;
			font-size: 1.6rem;
			font-weight: $fw-medium;
		}
	}
}