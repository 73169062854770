@charset "utf-8";

/*===============================================
 header
===============================================*/
.l-header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-header;
	width: 100%;
	height: 132px;
	background-color: $color-white;
	transform: translateY(0%);
	transition: transform 1.2s $ease-out-circ;
	
	@media #{$sp} {
		height: spvw( 90 );
	}
	
	&.is-fixed {
		height: 77px;
		
		@media #{$tb} {
			height: 56px;
		}
		@media #{$sp} {
			height: calc( #{spvw(20)} + 24px );
		}
	}
	&.is-hidden {
		transform: translateY(-100%);
	}
	&__in {
		padding-top: 10px;
		@include clearfix;
		
		@media #{$sp} {
			padding-top: spvw(20);
		}
	}
	&.is-fixed &__in {
		@media #{$sp} {
			padding-top: spvw(10);
		}
	}
	&__logo {
		float: left;
		width: 186px;
		margin-top: 14px;
		
		@media #{$sp} {
			width: spvw( 120 );
			margin-top: 0;
		}
		> a {
			display: block;
			
			> img {
				display: block;
			}
		}
	}
	&.is-fixed &__logo {
		display: none;
	}
	&__logo-s {
		display: none;
		float: left;
		width: 148px;
		margin-top: 8px;
		
		@media #{$sp} {
			width: spvw( 90 );
			margin-top: spvw( -2 );
		}
		> a {
			display: block;
			
			> img {
				display: block;
			}
		}
	}
	&.is-fixed &__logo-s {
		display: block;
	}
	&__corpo {
		float: right;
		
		@media #{$sp} {
			margin-top: 0;
		}
		> a {
			display: block;
			width: 442px;
			height: 55px;
			background: url(#{$img}common/header-corpo.png) no-repeat right top;
			background-size: contain;
			
			@media #{$tb} {
				width: calc( 522px * 0.9 );
				height: calc( 65px * 0.9 );
			}
			@media #{$sp} {
				width: spvw( 522 * 0.4 );
				height: spvw( 65 * 0.4 );
			}
		}
	}
	&.is-fixed &__corpo {
		display: none;
	}
	&__admin {
		clear: right;
		float: right;
		margin-top: 8px;
		color: #0062a0;
		font-size: 22px;
		font-weight: bold;
	}
	&__nav {
		clear: right;
		float: right;
		display: flex;
		margin-top: 4px;
		
		@media #{$tb} {
			clear: none;
			width: calc( 100% - 216px );
			justify-content: flex-end;
			margin-top: 28px;
		}
		@media #{$sp} {
			width: calc( 100% - #{spvw(120)} );
			margin-top: spvw( 10 );
		}
		
		> ul {
			display: flex;
			
			@media #{$tb} {
				pointer-events: none;
				opacity: 0;
				flex-direction: column;
				position: absolute;
				left: 10%;
				top: 132px;
				width: 90%;
				max-height: calc( 100vh - 150px );
				background-color: #fff;
				box-shadow: 0 6px 6px rgba(0,0,0,0.5);
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
				transform: translateX(50%);
				transition: opacity .3s, transform .3s ease-out;
			}
			@media #{$sp} {
				top: spvw( 90 );
				max-height: calc( 100vh - #{spvw( 90 )} );
			}
			> li {
				&:not(:first-child) {
					margin-left: 10px;
					
					@media #{$tb} {
						margin-left: 0;
						border-top: 1px solid #ccc;
					}
				}
				> a {
					position: relative;
					display: block;
					padding: 18px 8px 28px;
					color: #000000;
					font-size: 17px;
					font-weight: $fw-medium;
					line-height: 1;
					white-space: nowrap;
					
					@media #{$tb} {
						padding: 20px 22px 22px;
					}
					&:before {
						position: absolute;
						left: 50%;
						top: 48px;
						width: 36px;
						height: 3px;
						background-color: #0082ba;
						opacity: 0;
						transform: translateX(-50%) scaleX(0);
						transition: opacity .2s, transform .2s ease-out;
						content: '';
						
						@media #{$tb} {
							display: none;
						}
					}
					&.is-current:before {
						opacity: 1;
						transform: translateX(-50%) scaleX(1);
					}
					&:after {
						display: none;
						width: 10px;
						height: 7px;
						background: url(#{$img}common/gnav-arw.png) no-repeat;
						content: '';
						
						@media #{$tb} {
							position: absolute;
							right: 24px;
							top: 50%;
							display: block;
							transform: translateY(-50%) rotate(-90deg);
						}
					}
				}
				&:hover > a {
					&:before {
						opacity: 1;
						transform: translateX(-50%) scaleX(1);
					}
				}
			}
		}
	}
	&.is-fixed &__nav {
		@media #{$tb} {
			margin-top: 8px;
		}
		@media #{$sp} {
			margin-top: 0;
		}
		> ul {
			@media #{$tb} {
				top: 56px;
			}
			@media #{$sp} {
				top: calc( #{spvw(20)} + 24px );
			}
		}
	}
	&__nav.is-active {
		> ul {
			@media #{$tb} {
				opacity: 1;
				transform: translateX(0%);
				pointer-events: auto;
			}
		}
	}
	&__spmenu {
		display: none;
		
		@media #{$tb} {
			position: relative;
			display: block;
			width: 24px;
			height: 24px;
			// margin-right: 8px;
		}
		.border {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			height: 3px;
			background-color: #1a4e8a;
			transition: opacity .2s, transform .2s ease-out;
			
			&:nth-child(1) {
				transform: translateY(-2px);
			}
			&:nth-child(2) {
				transform: translateY(-10px);
			}
			&:nth-child(3) {
				transform: translateY(6px);
			}
		}
	}
	&__nav.is-active &__spmenu {
		.border {
			&:nth-child(1) {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: translateY(-2px) rotate(45deg);
			}
			&:nth-child(3) {
				transform: translateY(-2px) rotate(-45deg);
			}
		}
	}
	&__parent {
		> a {
			border-radius: 8px 8px 0 0;
			
			@media #{$tb} {
				border-radius: 0;
			}
			&:after {
				position: static !important;
				display: inline-block !important;
				margin: 0 0 0 6px !important;
				transform: none !important;
			}
		}
		&:hover > a {
			background-color: #dde5ed;
			color: #1a4e8a;
			opacity: 1 !important;
			
			@media #{$tb} {
				background-color: transparent;
				color: #000000;
			}
		}
		&.is-hover > a {
			color: #1a4e8a;
			background-color: #dde5ed;
			opacity: 1 !important;
			
			@media #{$tb} {
				color: #000000;
				background-color: transparent;
			}
			&:after {
				transform: rotate(180deg) !important;
			}
		}
	}
	&__child {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		top: 100%;
		width: 100%;
		
		@media #{$tb} {
			position: relative;
			top: 0;
		}
	}
	/*
	&__parent:hover &__child {
		@media #{$pc} {
			display: block;
		}
	}
	*/
	&__parent.is-hover &__child {
		display: block;
	}
	&__search {
		padding: 18px 0 0 0;
		
		@media #{$tb} {
			padding: 0;
		}
		> a {
			display: block;
			width: 24px;
			height: 24px;
			padding: 0;
			background: url(#{$img}common/gnav-search.png) no-repeat center center;
		}
	}
	&-drop {
		width: 100%;
		background-color: #dde5ed;
		
		&__in {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 40px 0;
			
			@media #{$tb} {
				padding: 0 !important;
			}
		}
		&__heading {
			// width: 160px;
			width: percentage( 160 / $pc-center );
			flex: 0 0 none;
			
			@media #{$tb} {
				display: none;
			}
		}
		&__ttl {
			&-ja {
				display: block;
				font-size: 24px;
				line-height: 1;
			}
			&-en {
				display: block;
				margin-top: 16px;
				color: #1a4e8a;
				font-size: 16px;
				line-height: 1;
			}
		}
		&__body {
			display: flex;
			justify-content: space-between;
			flex: 1 1 auto;
			padding: 0 0 0 40px;
			border-left: 1px solid #ffffff;
			
			@media #{$tb} {
				flex-direction: column;
				padding: 0;
				border-left: 0;
			}
		}
		&__sect {
			padding-top: 12px;
			padding-bottom: 12px;
			border-top: 1px solid #fff;
			
			@media #{$tb} {
				padding-top: 0;
				padding-bottom: 0;
				border-top: 0;
			}
			&:first-child {
				padding-top: 0;
				border-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
		&__column {
			@media #{$tb} {
				margin-top: 1.6rem;
				
				&:first-child {
					margin-top: 0;
				}
			}
			p {
				line-height: 1;
				
				@media #{$tb} {
					border-top: 1px solid #ccc;
					font-size: 17px;
				}
				a {
					color: #1a4e8a;
					
					@media #{$tb} {
						position: relative;
						display: block;
						padding: 20px 22px 22px 48px;
						color: #000000;
						
						&:before {
							position: absolute;
							left: 26px;
							top: 50%;
							width: 8px;
							height: 1px;
							background-color: #0082ba;
							content: '';
						}
						&:after {
							position: absolute;
							right: 24px;
							top: 50%;
							display: block;
							width: 10px;
							height: 7px;
							background: url(#{$img}common/gnav-arw.png) no-repeat;
							transform: translateY(-50%) rotate(-90deg);
							content: '';
						}
					}
				}
			}
			ul {
				display: flex;
				
				@media #{$tb} {
					display: block;
					border-top: 1px solid #ccc;
				}
				> li {
					position: relative;
					max-width: 202px;
					
					@media #{$tb} {
						max-width: 100%;
					}
					&:not(:first-child) {
						margin-left: 24px;
						
						@media #{$tb} {
							margin-left: 0;
							border-top: 1px solid #ccc;
						}
					}
					/*
					&:after {
						position: absolute;
						right: 0;
						bottom: 0;
						width: 35px;
						height: 22px;
						background: url(#{$img}common/gnav-menu-arw.svg) no-repeat right bottom;
						background-size: 100% 100%;
						pointer-events: none;
						content: '';
					}
					*/
					a {
						display: block;
						border: 1px solid #249188;
						border-radius: 12px;
						overflow: hidden;
						
						@media #{$tb} {
							position: relative;
							padding: 20px 22px 22px 48px;
							border: 0;
							border-radius: 0;
							
							&:before {
								position: absolute;
								left: 26px;
								top: 50%;
								width: 8px;
								height: 1px;
								background-color: #0082ba;
								content: '';
							}
							&:after {
								position: absolute;
								right: 24px;
								top: 50%;
								display: block;
								width: 10px;
								height: 7px;
								background: url(#{$img}common/gnav-arw.png) no-repeat;
								transform: translateY(-50%) rotate(-90deg);
								content: '';
							}
						}
						figure {
							position: relative;
							display: block;
							margin: 0;
							font-size: 12px;
							
							@media #{$tb} {
								font-size: 17px;
							}
							&:before {
								position: absolute;
								left: 0;
								right: 0;
								bottom: 0;
								// height: 30%;
								height: 3em;
								// background: linear-gradient(to top, rgba(0,93,149,1) 0%,rgba(0,93,149,0.8) 18%,rgba(0,93,149,0) 100%);
								background: rgba(0,93,149,0.6);
								transition: height .2s;
								content: '';
								
								@media #{$tb} {
									display: none;
								}
							}
							img {
								display: block;
								
								@media #{$tb} {
									display: none;
								}
							}
							figcaption {
								position: absolute;
								left: 0;
								bottom: 1em;
								width: 100%;
								box-sizing: border-box;
								text-align: center;
								color: #ffffff;
								font-weight: bold;
								line-height: 1;
								transition: bottom .2s;
								
								@media #{$tb} {
									position: relative;
									bottom: 0;
									text-align: left;
									color: #000000;
									font-weight: normal;
									transition: none;
								}
							}
						}
						&:hover {
							opacity: 1 !important;
						}
						&:hover figure {
							&:before {
								height: 100%;
							}
							figcaption {
								bottom: calc( 50% - 0.5em );
							}
						}
					}
				}
			}
		}
	}
}
