.m-grid {
	&-col01,
	&-col02,
	&-col03,
	&-col04,
	&-col05 {
		display: flex;
		flex-wrap: wrap;
		
		&--centering {
			justify-content: center;
		}
	}
	&-item {
		&__thumb {
			position: relative;
			display: block;
			border-radius: 12px;
			margin: 0;
			overflow: hidden;
			font-size: 1.6rem;
			
			@media #{$sp} {
				font-size: 1.4rem;
			}
			img {
				display: block;
				width: 100%;
			}
			&--border {
				border: 1px solid #249188;
			}
		}
		&__link {
			position: relative;
			display: block;
			
			picture,
			img {
				display: block;
				width: 100%;
				height: auto;
			}
			&:before {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				// height: 20%;
				// height: 3.6em;
				height: 3em;
				// background: linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,0.8) 18%,rgba(0,0,0,0) 100%);
				background: rgba(0,93,149,0.6);
				pointer-events: none;
				transition: height .2s;
				content: '';
			}
		}
		&__link:hover {
			opacity: 1 !important;
			
			&:before {
				height: 100%;
			}
		}
		&__caption {
			position: absolute;
			left: 0;
			// bottom: 1.2em;
			bottom: 1em;
			z-index: 0;
			width: 100%;
			box-sizing: border-box;
			text-align: center;
			color: #ffffff;
			font-weight: bold;
			line-height: 1;
			pointer-events: none;
			transition: bottom .2s;
		}
		&__link:hover &__caption {
			bottom: calc( 50% - 0.6em );
		}
		&__content {
			padding-top: 1.6rem;
			padding-bottom: 1.6rem;
		}
		&__ttl {
			color: #1a4e8a;
			line-height: 1.5;
		}
		&__txt {
			margin-top: 0.4rem;
			line-height: 1.5;
			
			@media #{$sp} {
				font-size: 1.2rem;
			}
			
			&.is-small {
				font-size: 1.3rem;
				
				@media #{$sp} {
					font-size: 1.1rem;
				}
			}
		}
	}
	&-col01 {
		justify-content: center;
	}
	&-col01 &-item {
		width: percentage( 705 / $pc-center );
		
		@media #{$sp} {
			width: 100%;
		}
	}
	&-col02 &-item {
		width: percentage( 470 / $pc-center );
		margin-left: percentage( ($pc-center - 470 * 2) / $pc-center );
		margin-top: 2.8rem;
		
		&:nth-child(-n+2) {
			margin-top: 0;
		}
		&:nth-child(2n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			width: 100%;
			margin-left: 0;
			
			&:nth-child(-n+2) {
				margin-top: 2.8rem;
			}
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&-col03 &-item {
		width: percentage( 300 / $pc-center );
		margin-left: percentage( ($pc-center - 300 * 3) / 2 / $pc-center );
		margin-top: 4.4rem;
		
		&:nth-child(-n+3) {
			margin-top: 0;
		}
		&:nth-child(3n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-left: 0;
			margin-top: 2.0rem;
			
			&:nth-child(-n+3) {
				margin-top: 2.0rem;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			&:first-child {
				margin-top: 0;
			}
			&__thumb {
				width: 48%;
				
				&:last-child {
					width: 100%;
				}
			}
			&__content {
				width: 48%;
			}
		}
	}
	&-col04 &-item {
		width: percentage( 215 / $pc-center );
		margin-left: percentage( ($pc-center - 215 * 4) / 3 / $pc-center );
		margin-top: 3.2rem;
		
		&:nth-child(-n+4) {
			margin-top: 0;
		}
		&:nth-child(4n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			width: 48%;
			margin-left: 4%;
			margin-top: 1.6rem;
			
			&:nth-child(-n+4) {
				margin-top: 1.6rem;
			}
			&:nth-child(4n+1) {
				margin-left: 4%;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
		}
	}
	&-col05 &-item {
		width: 19%;
		margin-left: 1.25%;
		margin-top: 3.2rem;
		
		&:nth-child(-n+5) {
			margin-top: 0;
		}
		&:nth-child(5n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			width: 48%;
			margin-left: 4%;
			margin-top: 1.6rem;
			
			&:nth-child(-n+5) {
				margin-top: 1.6rem;
			}
			&:nth-child(5n+1) {
				margin-left: 4%;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
		}
	}
}