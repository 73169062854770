.m-column {
	&01,
	&02 {
		display: flex;
		justify-content: space-between;
		
		&--image-left {
			flex-direction: row-reverse;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&--image-right {
			flex-direction: row;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&--center {
			align-items: center;
		}
		&:first-child {
			margin-top: 0;
		}
		&__image {
			text-align: center;
		}
		&--image-left &__image {
			@media #{$sp} {
				margin-top: 4.0rem;
			}
		}
		&__content {
			@media #{$sp} {
				// padding-right: 20px;
				// padding-left: 20px;
				max-width: 100%;
				box-sizing: border-box;
			}
		}
		&--image-right &__content {
			@media #{$sp} {
				margin-top: 4.0rem;
			}
		}
	}
	&01 {
		&__image {
			// width: percentage( 540 / $pc-inner );
			// width: percentage( 520 / $pc-inner );
			width: percentage( 450 / $pc-inner );
			
			@media #{$sp} {
				width: 100%;
			}
			
			.m-imagebox {
				display: block;
				
				&__in {
					> img {
						width: 100%;
					}
				}
			}
		}
		&__content {
			// width: percentage( 550 / $pc-inner );
			width: percentage( 620 / $pc-inner );
			
			@media #{$sp} {
				width: 100%;
			}
			
			figure {
				max-width: 400px;
				
				@media #{$sp} {
					max-width: 100%;
				}
			}
		}
		&--image-fixed &__image {
			@media #{$large} {
				flex: 0 0 auto;
				width: 440px;
			}
		}
		&--image-fixed &__content {
			@media #{$large} {
				flex: 0 0 auto;
				width: calc( 100% - 440px - 62px );
			}
		}
	}
	&02 {
		&__image {
			width: calc( 50% - 10px );
			text-align: center;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&__content {
			width: calc( 50% - 10px );
			
			@media #{$sp} {
				width: 100%;
			}
		}
	}
	&03 {
		display: flex;
		justify-content: space-between;
		
		@media #{$sp} {
			flex-direction: column;
		}
		&:first-child {
			margin-top: 0;
		}
		&__table {
			width: percentage( 580 / $pc-inner );
			
			@media #{$sp} {
				width: 100%;
				padding-left: 20px;
				padding-right: 20px;
				margin-top: 4.0rem;
				box-sizing: border-box;
			}
			.m-imagebox {
				display: block;
				
				&__in {
					> img {
						width: 100%;
					}
				}
			}
			&__scroll {
				width: 100%;
				overflow-x: auto;
			}
		}
		&__content {
			width: percentage( 500 / $pc-inner );
			
			@media #{$sp} {
				width: 100%;
				max-width: 100%;
				padding-left: 20px;
				padding-right: 20px;
				box-sizing: border-box;
			}
		}
	}
}