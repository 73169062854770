.m-images {
	&__main {
		> figure {
			display: none; // default
			
			a {
				position: relative;
				display: block;
				
				&:before {
					position: absolute;
					right: 1px;
					bottom: 1px;
					width: 0;
					height: 0;
					border-right: 14px solid #f8f8f8;
					border-bottom: 14px solid #f8f8f8;
					border-top: 14px solid transparent;
					border-left: 14px solid transparent;
					opacity: 0.9;
					content: '';
				}
				&:after {
					position: absolute;
					right: 3px;
					bottom: 4px;
					width: 12px;
					height: 12px;
					background: url(#{$img}download/ico-zoom.svg) no-repeat;
					background-size: 100% 100%;
					pointer-events: none;
					content: '';
				}
			}
			img,
			video {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
	&__nav {
		display: flex;
		// justify-content: center;
		padding-top: 10px;
		padding-bottom: 10px;
		
		> li {
			position: relative;
			width: 74px;
			margin-left: 10px;
			cursor: pointer;
			
			&:first-child {
				margin-left: 0;
			}
			> img {
				display: block;
				width: 100%;
			}
			&.is-video {
				&:after {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 18px;
					height: 13px;
					background: url(#{$img}company/network/ico-play.png) no-repeat;
					content: '';
				}
			}
		}
		&.is-col-3 {
			> li {
				width: calc( 100% * 90 / 300 );
				
				&:not(:first-child) {
					margin-left: calc( 100% * 15 / 300 );
				}
			}
		}
	}
}