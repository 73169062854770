@charset "utf-8";

$z-side: 1000;
$z-header: 950;
$z-zoom: 900;
$z-pagetop: 850;
$z-agreement: 800;
$z-sticky: 750;
$z-search: 700;
$z-control: 650;
$z-footer: 600;